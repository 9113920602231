import Joi from '@hapi/joi';

// Lib
import { isValidNumber } from 'libphonenumber-js';
import { isEmpty } from 'lodash';
// import moment from 'moment-timezone';
import { commaSeparatedFormat } from 'utils/helper/numberHelper';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

// Constant
// import {
//   DATE_INPUT_FORMAT_ALLOWED,
//   TIME_INPUT_FORMAT_ALLOWED,
// } from '../../../config/constants/business';

export const required = (attribute: string, t: TypeOfT) => {
    return t(`validation.required`, { attribute: t(`words.${attribute}`) });
};

export const requiredOnly = (t: TypeOfT) => {
    return t(`validation.requiredOnly`);
};

export const emailFormat = (attribute: string, t: TypeOfT) => {
    return (value: string) => {
        if (isEmpty(value)) return true;

        const result = Joi.string()
            .email({
                allowUnicode: false,
                tlds: { allow: false },
            })
            .required()
            .validate(value);

        return !result.error
            ? true
            : t('validation.email', { attribute: t(`words.${attribute}`) });
    };
};

export const noUpperCase = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        !/[A-Z]/.test(value) ||
        (t('validation.noUpperCaseCharacters', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const minLength = (attribute: string, length: number, t: TypeOfT) => {
    return {
        value: Number(length),
        message: t(`validation.gte.string`, {
            attribute: t(`words.${attribute}`),
            value: length,
        }) as string,
    };
};

export const alphaNumeric = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /^[0-9a-zA-Z]*$/.test(value) ||
        (t('validation.alphaNumeric', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const includeLowercase = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /[a-z]/.test(value) ||
        !value ||
        (t('validation.includeLowercase', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const includeUppercase = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /[A-Z]/.test(value) ||
        !value ||
        (t('validation.includeUppercase', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const includeNumber = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /[0-9]/.test(value) ||
        !value ||
        (t('validation.includeNumber', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const includeSpecialCharacter = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /[@!#$%*()\-_+={}[\]|\\;:<>,./?]/.test(value) ||
        !value ||
        (t('validation.includeSpecialCharacter', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const noSpecifiedCharacter = (
    attribute: string,
    characters: string,
    t: TypeOfT,
) => {
    return (value: string) =>
        !new RegExp(`[${characters}]`).test(value) ||
        (t('validation.noSpecifiedCharacter', {
            attribute: t(`words.${attribute}`),
            // eslint-disable-next-line
            characters: characters.replace(/\\/g, ''),
        }) as string);
};

export const passwordEqualToConfirm = (confirmPassword: string, t: TypeOfT) => {
    return (value: string) =>
        value === confirmPassword ||
        (t(`validation.passwordEqualToConfirm`) as string);
};

export const onlyCharacters = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /^[a-zA-Z]*$/.test(value) ||
        (t('validation.onlyCharacters', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const onlyNumbers = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /^[0-9]*$/.test(value) ||
        (t('validation.regex', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const validAddress = (attribute: string, t: TypeOfT) => {
    return (value: string) =>
        /^[#.0-9a-zA-Z\s,-/]*$/.test(value) ||
        (t('validation.validAddress', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const validPhoneNumber = (countryCode: string, t: TypeOfT) => {
    return (value: string) => {
        const internationalNumber = countryCode + value;

        if (isEmpty(countryCode))
            return t(`validation.countryCodeRequired`) as string;
        return (
            isValidNumber(internationalNumber) ||
            (t(`validation.validPhoneNumber`) as string)
        );
    };
};

export const regex = (attribute: string, character: string, t: TypeOfT) => {
    return (value: string) =>
        new RegExp(character).test(value) ||
        (t('validation.regex', {
            attribute: t(`words.${attribute}`),
        }) as string);
};

export const validNormalPhone = (attribute: string, t: TypeOfT) =>
    regex(attribute, '^[0-9]+$', t);

export const min = (
    attribute: string,
    minimum: number,
    fixed: number,
    t: TypeOfT,
) => {
    return (value: string | number) =>
        Number(String(value).replace(/,/g, '')) >= minimum ||
        (t('validation.min.numeric', {
            attribute: t(`words.${attribute}`),
            min: commaSeparatedFormat(minimum, fixed),
        }) as string);
};

export const max = (
    attribute: string,
    maximum: number,
    fixed: number,
    t: TypeOfT,
) => {
    return (value: string | number) =>
        Number(String(value).replace(/,/g, '')) <= maximum ||
        (t('validation.max.numeric', {
            attribute: t(`words.${attribute}`),
            max: commaSeparatedFormat(maximum, fixed),
        }) as string);
};

export const invalidNumber = (
    attribute: string,
    number: number,
    t: TypeOfT,
) => {
    return (value: string) =>
        !value ||
        Number(value.replace(/,/g, '')) !== number ||
        (t('validation.invalidNumber', {
            attribute: t(`words.${attribute}`),
            number: String(number),
        }) as string);
};

export const isNumber = (attribute: string, t: TypeOfT) => {
    return (value: string) => {
        const numString = value.replace(/,/g, '');

        return (
            !numString ||
            !Number.isNaN(Number(numString)) ||
            (t(`validation.invalidNumberFormat`, {
                attribute: t(`words.${attribute}`),
            }) as string)
        );
    };
};

// export const validDate = (t: TypeOfT) => {
//   return (value: string) => {
//     return (
//       !value ||
//       !!DATE_INPUT_FORMAT_ALLOWED.reduce(
//         (isValid, format) => isValid || moment(value, format, true).isValid(),
//         false,
//       ) ||
//       t(`validation.validDate`)
//     );
//   };
// };

// export const validTime = (t: TypeOfT) => {
//   return (value: string) =>
//     !value ||
//     !!TIME_INPUT_FORMAT_ALLOWED.reduce(
//       (isValid, format) => isValid || moment(value, format, true).isValid(),
//       false,
//     ) ||
//     t(`validation.validTime`);
// };

export const checked = () => {
    return (value: string) => value === 'checked';
};

export const minDeposit = () => {
    return (value: string) => value === 'checked';
};

export const validZipCode = (attribute: string, t: TypeOfT) =>
    regex(attribute, '^(^$|[0-9０-９{3}-[0-9０-９{4}|ー)$', t);

export const validKatakana = (attribute: string, t: TypeOfT) =>
    regex(attribute, '^(^$|[ァ-ンｧ-ﾝﾞﾟ_ 　]|ー)+$', t);
